import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import VersandlogistikIntro from '../components/Versandlogistik/VersandlogistikIntro'
import FourFactors from "../components/Versandlogistik/FourFactors"
import Seo from "../components/App/Seo"
import FooterLanding from "../components/App/FooterLanding"

const AboutUs = () => {
  return (
    <Layout>
      <Seo title={"Versandlogistik im Überblick"}
           description={"Darstellung der wichtigsten Faktoren für erfolgreiche " +
           "Versandlogistik. Verschicken Sie Sendungen so effizient wie nie."}
           image={"/images/og/netversys-main-og.png"}
      />
      <Navbar showNavigation={false} showNetversysButton={true}/>
      <VersandlogistikIntro />
      <FourFactors />
      <FooterLanding/>
    </Layout>
  );
}


export default AboutUs;
