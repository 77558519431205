import React from "react"
import { Link } from "gatsby"
import { Row, Col } from "react-bootstrap"
import LandingPageForm from "../Common/LandingPageForm"


const VersandlogistikIntro = () => {
  return (
    <section className="about-area bg-fafafb pt-4 pb-4 px-5" style={{ marginTop: "6%" }}>
      <div className="container-fluid">
        <Row className="align-items-center">
          <Col lg={8} md={12}>
            <div className="about2-content">
              <div className="content">
                <h1>Die Versandlogistik im Überblick</h1>
                <p className={"border-left pl-3 border-primary"}>
                  Die Versandlogistik ist ein wichtiger Teil der Intralogistik und der letzte
                  Prozess, bevor Ware mit Dienstleistern zu Kunden oder Händlern gelangt. Typischerweise sind Bausteine
                  wie Erstellung des Versandlabels, EDI Datenaustausch mit Carriern, Track & Trace,
                  Routenfindung, <Link to={"/versandsoftware/netversys/gefahrgutversand/"}>Gefahrguthandling</Link> oder
                  ATLAS Zollmanagement Teil von Versandlogistikprodukten
                  wie <Link to={"/versandsoftware/"}>NETVERSYS</Link>.
                </p>

                <h2 className={"h4 text-primary"}>Stückgut und KEP Paketversand</h2>
                <p>
                  Das Wort Versandlogistik impliziert sowohl den Versand von Paketen mittels KEP<i
                  className="bx bx-info-circle" title={"Kurier, Express und Paketdienste"} /> Dienstleistern,
                  als auch den Versand von Stückgut via Speditionen. Die Anforderungen
                  an die Systeme unterscheiden sich stark in beiden Bereichen. NETVERSYS vereint die Welten,
                  sodass Kunden sowohl Paket-, als auch Stückgutversand realisieren können. Einem Mischbetrieb von
                  B2C und B2B Sendungen steht nichts im Weg.
                </p>

                <h2 className={"h4  text-primary"}>Integration in Vorsysteme</h2>
                <p>
                  Die Versandlogistik ist integraler Bestandteil der Supply-Chain und erfordert eine nahtlose
                  Integration in Vorsysteme wie <Link to={"/lagerverwaltung/"}>WMS</Link>, ERP oder Shops.
                  Die Schnittstellen sind dabei bidirektional, da neben Auftragsdaten, die in das Versandsystem
                  gelangen, auch Daten durch das Versandsystem erstellt werden, welche
                  in ERP- oder Shopsysteme zurückgemeldet werden. NETVERSYS unterstützt viele Wege mit
                  Drittsystemen zu interagieren.
                </p>
              </div>
            </div>
          </Col>
          <Col lg={4} md={12}>
            <LandingPageForm/>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default VersandlogistikIntro
