import React from "react"
import {Row, Col, Container} from 'react-bootstrap'
import {Link} from 'gatsby'
import serviceIcon1 from "../../assets/images/versandlogistik/speed.svg"
import serviceIcon2 from "../../assets/images/versandlogistik/hardwareneutral.svg"
import serviceIcon3 from "../../assets/images/versandlogistik/automation.svg"
import serviceIcon4 from "../../assets/images/versandlogistik/flexibility.svg"


const FourFactors = () => {
  return (
    <section className="services-area pt-5 pb-70 bg-f1f8fb">
      <Container>
        <div className="section-title">
          <h2>4 Faktoren für erfolgreiche Versandlogistik</h2>
        </div>

        <Row>
          <Col sm={6} className={"p-3"}>
            <div className="single-services-item-box h-100">
              <div className="icon">
                <img src={serviceIcon1} alt="Geschwindigkeit in der Versandlogistik" style={{width: "70px"}}/>
              </div>
              <h3>
                  Geschwindigkeit
              </h3>
              <p>
                Die Zeit, die für den Workflow am Versandarbeitsplatz benötigt wird, ist integraler Faktor bei der
                Bewertung einer erfolgreichen Versandlogistik. Wenn es mit einer Versandlogistiksoftware möglich ist, fünf
                Sekunden pro Versanddurchlauf zu sparen, dann ergibt das bei einem vierstelligen Versandaufkommen am Tag
                ca. 1,5 Stunden, die sich in der Personalplanung niederschlägt. Achten Sie darauf, dass die
                Software auf optimale Geschwindigkeit am Arbeitsplatz ausgelegt ist.
              </p>
            </div>
          </Col>

          <Col sm={6} className={"p-3"}>
            <div className="single-services-item-box h-100">
              <div className="icon">
                <img src={serviceIcon2} alt="Hardwareneutralität in der Versandlogistik" style={{width: "70px"}}/>
              </div>
              <h3>
                  Hardwareneutralität
              </h3>
              <p>
                Die Software sollte wenn möglich hardwareneutral sein. Es ist ein großer Vorteil Hardware wie Waagen,
                mobile Computer oder Drucker, nach den spezifischen Anforderungen des Betriebs auszusuchen. Die Software
                sollte in der Lage sein mit vielen Konstellationen zu arbeiten. Das gleiche gilt natürlich auch für die
                Wahl des Servers, wenn die Versandlogistiksoftware serverbasiert ist.
                Die <Link to={"/versandsoftware/netversys/technologie/"}>Technologie</Link> spielt in jedem Fall eine
                bedeutende Rolle bei hardwarenaher Software.
              </p>
            </div>
          </Col>

          <Col sm={6} className={"p-3"}>
            <div className="single-services-item-box h-100">
              <div className="icon">
                <img src={serviceIcon3} alt="Automatisierung in der Versandlogistik"  style={{width: "70px"}}/>
              </div>

              <h3>
                  Automatisierung
              </h3>

              <p>
                Bei wachsenden Unternehmen stellt sich ab einer gewissen Größe die Frage, ob der Versand nicht
                komplett <Link to={"/versandsoftware/netversys/vollautomatisierung/"}>automatisiert</Link> oder
                zumindest über das ERP System gesteuert werden soll, ohne das es eine Interaktion mit
                der Versandsoftware gibt. Viele Versandlogistiksysteme sind darauf nicht vorbereitet, was eine
                Neuanschaffung zur Folge hat. Falls ein Mischbetrieb von manuellem und automatischen Versand nötig sein
                soll, ist es noch wichtiger eine Software zu haben, die beide Modus parallel unterstützt.
              </p>

            </div>
          </Col>

          <Col sm={6} className={"p-3"}>
            <div className="single-services-item-box h-100">
              <div className="icon">
                <img src={serviceIcon4} alt="Flexibilität in der Versandlogistik"  style={{width: "70px"}}/>
              </div>

              <h3>
                  Flexibilität
              </h3>

              <p>
                Software im Bereich Versandlogistik ist, anders als Software in anderen Bereichen, immer in Bewegung.
                Gründe sind Änderungen bei Produkten und Services der Carrier oder neu entstehende KEP- und
                Stückgutdienstleister. Der Softwarehersteller hinter dem Produkt sollte also reaktionsfreudig sein und
                Anforderungen von Kunden ernst nehmen. Anders als z.B. bei CRM Systemen, ist im Logistikumfeld die
                Interaktion mit dem Produkthersteller häufiger und bedarf einer guten und engen Kommunikation.
              </p>

            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default FourFactors
